<template>
  <b-container style="height: 100vh;" class="flex-grow-1">
    <b-row class="h-100">
      <b-col class="mx-auto my-auto" cols="12" md="8" lg="5">
        <div class="d-flex flex-column">
          <h4 class="text-center mt-4">
            Add funds to balance
          </h4>
          <p class="small text-center mb-4 ">You will be redirected to the cryptocloud payment page.</p>

          <a class="link small text-center mb-4" @click="showModal = true">
            How to buy USDT (using Trust Wallet as an example)
            <b-icon icon="hand-index-thumb" class="ml-1" />
          </a>

          <tp-icon-input v-model="amount" icon="usdt" placeholder="Amount" class="mb-3" />

          <div class="d-flex">
            <b-btn :disabled="(amount === '' || loading)" @click="startPay" class="button-gradient-primary scale-drawdown-animation w-100 mb-3" pill>
              Pay {{amount}}
              <b-spinner v-if="loading" small />
            </b-btn>
          </div>
        </div>

        <b-link class="link-gray-300" to="/home">
          <b-icon icon="chevron-left" /> Go Home
        </b-link>
      </b-col>
    </b-row>

    <b-modal v-model="showModal" title="How to buy USDT (using Trust Wallet as an example)" size="xl" hide-footer :content-class="($store.state.isWhiteTheme ? 'modal-white' : '')">
      <div class="payment-instructions">
        <p>You can use various wallets for topping up, and we'll look at an example using Trust Wallet.</p>

        <h4 class="mb-4">How to Install Trust Wallet and Buy USDT with Dollars</h4>
        
        <h5 class="mb-3">Step 1: Install Trust Wallet</h5>
        <h6>Download the App:</h6>
        <ul>
          <li>For Android: Open the Google Play Store and search for "Trust Wallet." Click Install.</li>
          <li>For iOS: Go to the App Store and find "Trust Wallet." Click Get.</li>
        </ul>
        
        <h6>Create a New Wallet:</h6>
        <ol>
          <li>Open the app after installation.</li>
          <li>Click on "Create New Wallet."</li>
          <li>Read and accept the Terms of Service. This is important!</li>
        </ol>
        
        <h6>Write Down the Recovery Phrase:</h6>
        <p>You will be prompted to create a recovery phrase (seed phrase). Write it down on paper and keep it in a safe place. This phrase is your key to access the wallet!</p>
        
        <h6>Confirm the Phrase:</h6>
        <p>Enter the words from the recovery phrase in the correct order to confirm.</p>
        
        <h5 class="mt-4 mb-3">Step 2: Set Up Your Wallet</h5>
        <h6>Add USDT:</h6>
        <ol>
          <li>Go to the main screen of your wallet.</li>
          <li>Click on "Add Token."</li>
          <li>Find USDT and activate it.</li>
        </ol>
        
        <h5 class="mt-4 mb-3">Step 3: Buying USDT with dollars</h5>
        <h6>Select "Buy":</h6>
        <p>Click on "Buy" on the main screen of your wallet.</p>
        
        <h6>Choose USDT:</h6>
        <p>Find USDT in the list of available cryptocurrencies.</p>
        
        <h6>Select Payment Method:</h6>
        <p>Trust Wallet integrates with several services like MoonPay or Simplex. Choose one of them.</p>
        
        <h6>Enter the Amount:</h6>
        <p>Specify how much USDT you want to buy with fiat (dollars, euros, etc.). Pay attention to the fees!</p>
        
        <h6>Enter Card Details:</h6>
        <p>Input your credit or debit card information. Ensure all details are correct.</p>
        
        <h6>Confirm the Purchase:</h6>
        <p>Review all information and click "Confirm." Wait for the transaction to complete — it might take a few minutes.</p>
        
        <h5 class="mt-4 mb-3">Congratulations! You Are Now the Owner of USDT!</h5>
        <p>After the transaction is completed, your USDT will appear in your wallet!</p>
        
        <h5 class="mt-4 mb-3">Congratulations! You Did It!</h5>
        <p>Now you have Trust Wallet and USDT ready for use! Enjoy securely managing your crypto assets, and remember to prioritize safety — keep your recovery phrase in a secure place!</p>

        <p>
          You can use any other wallet of your choice to use USDT
        </p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>

export default {
  name: "payments",
  data: () => ({
    amount: '',
    link: '',
    loading: false,
    showModal: false,
  }),

  methods: {
    startPay() {
      if(!this.loading) {
        this.loading = true

        this.axios(`payment/crypto_cloud_payment/${this.amount}`, {
          method: 'GET',
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')}
        })
            .then(response => {
              this.loading = false
              this.amount = ''
              this.link = response.data.result.link
              window.open(response.data.result.link);
            })
            .catch(() => {
              this.loading = false
            })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .payment-instructions {
    text-align: left;
  } 

  .white-theme {
    .link {
      background-color: blue;
    }
  }
</style>